import React, { useCallback } from 'react';
import {
    Button, Form, Input, Modal, ModalFuncProps, Radio, Tooltip, Typography, Upload,
} from 'antd';
import ym from 'react-yandex-metrika';
import { UploadOutlined } from '@ant-design/icons';
import { AuthLogin } from 'components/auth';
import { useDispatch } from 'react-redux';
import { createOrder } from 'ducks/orders/actions';
import { getToken } from 'utils/token';
import { buttonTracking } from 'utils/buttonTracking';

const { Title, Text } = Typography;

type Props = ModalFuncProps;

export const OrderAdd = ({
    title, visible, onCancel,
}: Props): any => {
    const [form] = Form.useForm();
    const put = useDispatch();
    const [isColoringtinting, setIsColoringtinting] = React.useState(false);
    const [isShade, setIsShade] = React.useState(false);

    const [authLogin, setAuthLogin] = React.useState(false);

    const onOk = useCallback(() => {
        form
            .validateFields()
            .then((values) => {
                if (getToken()) {
                    const fields = values;

                    if (values.image_before) {
                        const imageBefore = [];

                        for (let i = 0; i < values.image_before.fileList.length; i++) {
                            const files = values.image_before.fileList[i];

                            imageBefore.push(files.originFileObj);
                        }
                        fields.image_before = imageBefore;
                    }
                    if (values.image_after) {
                        const imageAfter = [];

                        for (let i = 0; i < values.image_after.fileList.length; i++) {
                            const files = values.image_after.fileList[i];

                            imageAfter.push(files.originFileObj);
                        }
                        fields.image_after = imageAfter;
                    }

                    fields.form = 'formula';
                    fields.formType = values.formType;

                    fields.brand = values.brand ? values.brand : 'other';
                    fields.isbrand = fields.brand === 'other' ? 1 : 0;

                    put(createOrder(fields, form.resetFields()));
                    buttonTracking('popup-register', 'Отправка заказа');
                    ym('reachGoal', 'push_order');
                } else {
                    setAuthLogin(true);
                    buttonTracking('popup-login', 'Авторизация');
                }
            });
    }, []);

    const formValuesChange = useCallback((type: any) => {
        if (type.formType === 'lamination') setIsColoringtinting(false);
        if (type.formType === 'coloringtinting') setIsColoringtinting(true);
        if (type.shade === 'yes') setIsShade(true);
        if (type.shade === 'no') setIsShade(false);
    }, []);

    const imageBeforeCount = isColoringtinting ? 3 : 2;

    const brandList = [
        { value: 'estel', name: 'Estel Professional' },
        { value: 'londa', name: 'Londa Professional' },
        { value: 'wella', name: 'Wella Professional' },
        // { value: 'schwarzkopfProf', name: 'Schwarzkopf Professional' },
        // { value: 'kapousProf', name: 'Kapous Professional' },
        { value: 'keune', name: 'Keune' },
        { value: 'other', name: 'Другое' },
    ];

    return (
        <React.Fragment>
            <Modal
                title={ title || undefined }
                visible={ visible }
                onCancel={ onCancel }
                onOk={ onOk }
                keyboard={ false }
                maskClosable={ false }
                footer={ (
                    <Button key="submit" type="primary" onClick={ () => onOk() }>
                        Заказать
                    </Button>
                ) }
            >
                <Title level={ 2 }>Заказать рецепт</Title>
                <Form
                    form={ form }
                    name="OrderAdd"
                    autoComplete="off"
                    layout="vertical"
                    onValuesChange={ formValuesChange }
                >
                    <Form.Item
                        label="Выберите тип"
                        name="formType"
                        rules={ [{ required: true, message: 'Выберите тип' }] }
                        initialValue="lamination"
                    >
                        <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                        >
                            <Radio.Button value="lamination">Ламинирование</Radio.Button>
                            <Radio.Button value="coloringtinting">Тонирование/окрашивание</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={ `Загрузите ${imageBeforeCount} фотографии ваших волос` }
                        name="image_before"
                        valuePropName="image_before"
                    >
                        <Upload
                            accept="image/*"
                            beforeUpload={ () => false }
                            multiple={ true }
                        >
                            <Tooltip title="Загрузите фото со спины и в профиль (в кадре обязательно должно быть видно всю длину волос), а так же фото прикорневой зоны (для этого разделите волосы прямым пробором посередине). Фото должно быть хорошего качества, без фильтров и при дневном освещении. ВАЖНО! Следите за тем, что бы прямые солнечные лучи не попали в кадр и на ваши волосы. ">
                                <Button icon={ <UploadOutlined /> }>Загрузить</Button>
                            </Tooltip>
                        </Upload>
                    </Form.Item>
                    { isColoringtinting && (
                        <Form.Item
                            label="Загрузите 3 фотографии результата"
                            tooltip="Загрузите 3 фотографии результата, который вы хотите получить в итоге"
                            name="image_after"
                            valuePropName="image_after"
                        >
                            <Upload
                                accept="image/*"
                                beforeUpload={ () => false }
                                multiple={ true }
                            >
                                <Tooltip title="Загрузите 3 фотографии результата, который вы хотите получить в итоге. Фотографии могут быть из разных источников, но иметь одно направление. Например, вы бы хотели покраситься в “холодный русый” цвет, значит, вы должны загрузить 3 фото, которые отображают, что  именно для вас означает “холодный русый” и как вы его для себя представляете.  Это поможет нам лучше понять ваши вкусовые предпочтения.">
                                    <Button icon={ <UploadOutlined /> }>Загрузить</Button>
                                </Tooltip>
                            </Upload>
                        </Form.Item>
                    ) }

                    <Form.Item
                        label="Опишите ваши волосы(выбрав по 1 из вариантов)"
                        name="hairType[0]"
                    >
                        <Radio.Group optionType="button" buttonStyle="solid">
                            <Radio.Button value="porous">Пористые</Radio.Button>
                            <Radio.Button value="smooth">Гладкие</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="hairType[1]"
                    >
                        <Radio.Group optionType="button" buttonStyle="solid">
                            <Radio.Button value="thin">Тонкие</Radio.Button>
                            <Radio.Button value="normal">Нормальные</Radio.Button>
                            <Radio.Button value="thick">Толстые</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="hairType[2]"
                    >
                        <Radio.Group optionType="button" buttonStyle="solid">
                            <Radio.Button value="curly">Кудрявые</Radio.Button>
                            <Radio.Button value="straight">Прямые</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    { isColoringtinting && (
                        <Form.Item
                            name="hairType[3]"
                        >
                            <Radio.Group optionType="button" buttonStyle="solid">
                                <Radio.Button value="natural">Натуральные</Radio.Button>
                                <Radio.Button value="colored">Окрашенные</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    ) }

                    { !isColoringtinting && (
                        <Form.Item
                            label="Какой оттенок блеска хотите получить"
                            name="shadeOfGloss"
                        >
                            <Radio.Group optionType="button" buttonStyle="solid">
                                <Radio.Button value="warm">Теплый</Radio.Button>
                                <Radio.Button value="neutral">Нейтральный</Radio.Button>
                                <Radio.Button value="cold">Холодный</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    ) }

                    <Form.Item
                        label="Оцените густоту"
                        name="density"
                    >
                        <Radio.Group optionType="button" buttonStyle="solid">
                            <Radio.Button value="liquid">Жидкие</Radio.Button>
                            <Radio.Button value="medium">Средние</Radio.Button>
                            <Radio.Button value="thick">Густые</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    { isColoringtinting && (
                        <Form.Item
                            label="Есть нежелательный оттенок"
                            name="shade"
                        >
                            <Radio.Group optionType="button" buttonStyle="solid">
                                <Radio.Button value="yes">Да</Radio.Button>
                                <Radio.Button value="no">Нет</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    ) }
                    { isColoringtinting && isShade && (
                        <Form.Item
                            label="Укажите какой оттенок вам не нравится"
                            name="shade_text"
                        >
                            <Input placeholder="Укажите какой оттенок вам не нравится" />
                        </Form.Item>
                    ) }

                    { isColoringtinting && (
                        <React.Fragment>
                            <Form.Item
                                label="Седина"
                                name="grayHair"
                            >
                                <Radio.Group optionType="button" buttonStyle="solid">
                                    <Radio.Button value="no">Нет</Radio.Button>
                                    <Radio.Button value="min30">Менее 30%</Radio.Button>
                                    <Radio.Button value="30_50">30-50%</Radio.Button>
                                    <Radio.Button value="more50">Более 50%</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Последний раз красились"
                                name="lastTimePainted"
                            >
                                <Radio.Group optionType="button" buttonStyle="solid">
                                    <Radio.Button value="aboutamonthago">Около месяца назад</Radio.Button>
                                    <Radio.Button value="morethanamonthago">Более месяца назад</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Опишите вашу основную проблему, которую хотите решить"
                                name="theMainProblem"
                            >
                                <Input.TextArea
                                    id="theMainProblem"
                                    name="theMainProblem"
                                    autoSize={ { minRows: 4, maxRows: 6 } }
                                    placeholder="Например, убрать желтизну, закрасить седину, прокрасить корни, выровнять цвет и т.д."
                                />
                            </Form.Item>
                        </React.Fragment>
                    ) }

                    <Form.Item
                        label="Бренд"
                        name="brand"
                        tooltip="[Другой] - Подобрать оптимальный для моих волос – мастер подберет вам краситель исходя из состояния и типа ваших волос и желаемого результата, при этом мастер будет руководствоваться алгоритмом – оптимальный вариант для достижения поставленного результата (максимальное приближение к желаемому оттенку)"
                    >
                        <Radio.Group optionType="button" buttonStyle="solid">
                            { brandList.map(({ value, name }) => <Radio value={ value }>{ name }</Radio>) }
                        </Radio.Group>
                    </Form.Item>

                </Form>
            </Modal>
            <AuthLogin
                discription={ <Text type="danger">Для того чтобы зарегистрировать заказ, Вам необходимо авторизоваться или зарегистироваться</Text> }
                visible={ authLogin }
                isRegister={ true }
                onCancel={ () => setAuthLogin(false) }
                onCallback={ () => setAuthLogin(false) }
            />
        </React.Fragment>
    );
};
